import React from "react";
import styled from "styled-components";
import { Button } from "./index";

const ButtonWrapper = styled.div`
  text-align: center;
  height: 200px;  
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    height: 60px;
  }
`;

const GetStarted = () => (
  <ButtonWrapper>
    <Button href={process.env.REACT_APP_DMF_VITA} target="_blank">
      Get started
    </Button>
  </ButtonWrapper>
);

export default GetStarted;
