import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Row, Col, GetStarted, Container, H2, Img, P, TryFree } from "./index";
import { useCookies } from "react-cookie";
import * as EmailValidator from 'email-validator';

const createDemoContactMutation = gql`
  mutation createDemoContact($input: CreateDemoContactInput!) {
    createDemoContact(input: $input) {
      email
    }
  }
`;

const Wrapper = styled.section`
 overflow: hidden;
`;

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "#FFFFFF",
    borderRadius: "16px",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
};

const ModalWrapper = styled.div`
  width: 80vw;
  padding-bottom: 2rem;
  z-index: 3;
`;

const ModalTitle = styled.p`
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
`;

const SignMeUp = styled.button`
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #1872F0;
  border: 2px solid #1872F0;
  border-radius: 6px;
  padding: 12px 37px;
  cursor: pointer;
`;

const SkipDemo = styled.a`
  text-align: center;
  font-size: 0.9rem;
  color: #111827;
  font-weight: bold;
  background-color: #ffffff;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  padding: 12px 5px;
  margin-left: 1rem;
  cursor: pointer;
`;

const EmailError = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 0.8rem;
  color: red;
`;

const PatternContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 404px;
  height: 404px;
  z-index: -1;
  max-width: 100%;
`;

const PatternBackdrop = styled.div `
  background-color: #F4F7FC;
  width: 453px;
  height: 640px;
  max-width: 100%;
`;

Modal.setAppElement("#root");

const OurMission = () => {
  const [cookies, setCookie] = useCookies(["subs"]);
  const [createDemoContact] = useMutation(createDemoContactMutation);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const [email, setEmail] = useState(null);

  const openModal = () => {
    if(window.location.href.includes("subs=true")){
      setCookie("subs", true, { path: "/" ,maxAge: 86400});
      return window.location.assign(
        process.env.REACT_APP_DMF_VITA + "#!demo?subs=true"
      );
    }
    else setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let emailError = EmailValidator.validate(value);
    if (value.length > 0) {
      setErrors(emailError ? "" : "Email is not valid!");
      setEmail(emailError ? value : null);
    } else {
      setErrors(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setErrors("You must specify email if you want to sign up");
    } else {
      createDemoContact({
        variables: {
          input: { email },
        },
      })
        .then((res) => {
          setCookie("subs", true, { path: "/" ,maxAge: 86400});
          return window.location.assign(
            process.env.REACT_APP_DMF_VITA + "#!demo?subs=true"
          );
        })
        .catch((e) => {
          const emailExists = "already exists";
          const mutationError = e.toString();

          if (mutationError.includes(emailExists))
            return setErrors("Email already exists");
          else return setErrors("Something went wrong");
        });
    }
  };

  const redirect = () => {
    return window.location.assign(
      process.env.REACT_APP_DMF_VITA + "#!demo?subs=true"
    );
  };

  const handleSkip = (e) => {
    e.preventDefault();
    window.location.assign(process.env.REACT_APP_DMF_VITA + "#!demo");
  };

  return (
    <Wrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}>
        <ModalWrapper>
          <div className="flex flex-row justify-end mb3">
            <i
              class="fas fa-times"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <ModalTitle>Do you want to stay</ModalTitle>
          <ModalTitle>connected with us?</ModalTitle>
          <form onSubmit={handleSubmit}>
            <div
              className="flex flex-column justify-center mt4"
              style={{ alignItems: "center" }}>
              {errors && errors.length > 0 && <EmailError>{errors}</EmailError>}
              <div className="email-input-wrapper">
                <input
                  type="text"
                  className={`email-input `}
                  placeholder="Enter your email address"
                  onChange={handleChange}/>
                <div className="input-icon">
                  <i className="far fa-envelope"></i>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center mt4">
              <SignMeUp type="submit">Sign me up</SignMeUp>
              <SkipDemo onClick={handleSkip}>Skip and access Demo</SkipDemo>
            </div>
          </form>
        </ModalWrapper>
      </Modal>
      <Container>
        <Row revertRowDesktop>
          <Col className="relative">
            <PatternContainer>
              <PatternBackdrop style={{position: "absolute", top: "-100px", right: "-40px"}}></PatternBackdrop>
              <Img style={{position: "absolute", top: "164px", right: "-40px"}} src="/img/square_pattern.svg" />
              <Img style={{position: "absolute", top: "-200px", right: "-40px"}} src="/img/square_pattern.svg" />
            </PatternContainer> 
            <Img src="/img/ourmission.png" alt="" />
          </Col>
          <Col centerMobile>
            <Img src="/img/dm-logo.svg" alt="" width="170px" mobile />
            <H2 w80 alignLeft style={{ marginBottom: 20, marginTop: 10 }}>
              A track record of helping young adults with developmental
              disabilities.
            </H2>
            <P alignLeft>
            VI-ready is a revolutionary technology that was developed for people 
            with Autism Spectrum Disorder(ASD) at the Marino Campus; a 10-month 
            post-secondary school for students with ASD and other developmental 
            disabilities. After seeing the students' interviewing skills improve, 
            the foundation decided everyone should have access to tools like VI-ready 
            because everyone deserves to experience real-world success.
            </P>
          </Col>
        </Row>
      </Container>
      <Row centerAlign mobileAlign>
        <GetStarted />
        <TryFree openModal={cookies.subs === "true" ? redirect : openModal} />
      </Row>
    </Wrapper>
  );
};

export default OurMission;
