import styled from "styled-components";

const TextContainer = styled.div`
  width: 1000px;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    width: 800px;
  }

  @media screen and (max-width: 900px) {
    width: 600px;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    padding: 1em;
  }
`;

export default TextContainer;
