import styled from "styled-components";

const H3 = styled.h3`
  color: #3a3f48;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
`;

export default H3;
