import styled, { css } from "styled-components";

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  ${props =>
    props.revertRowDesktop &&
    css`
      flex-direction: row-reverse;
    `}

  ${props =>
    props.pt0 &&
    css`
      padding-top: 0px;
    `}

    ${props =>
      props.centerAlign &&
      css`
        justify-content: center;
      `}

    ${props =>
        props.mobileAlign &&
        css`
        @media screen and (max-width: 800px) {
          align-items: center
        }
        `}
`;

export default Row;
