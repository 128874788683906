import styled, { css } from "styled-components";

const Img = styled.img`
  width: 100%;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.icon &&
    css`
      width: 100px;
      @media screen and (max-width: 600px) {
        width: 70px;
      }
    `}
`;

export default Img;
