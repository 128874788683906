import styled, { css } from "styled-components";

const H2 = styled.h2`
  color: #3a3f48;
  font-size: 44px;
  font-weight: 600;
  line-height: 50px;

  ${props =>
    props.w80 &&
    css`
      width: 80%;
    `}

  ${props =>
    props.alignLeft &&
    css`
      @media screen and (max-width: 800px) {
        text-align: left;
      }
    `}

  @media screen and (max-width: 800px) {
    font-size: 28px;
    text-align: center;
    line-height: 32px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export default H2;
