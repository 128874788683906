import styled, { css } from "styled-components";

const Button = styled.a`
  padding: 9px 34px;
  color: #fff;
  font-size: 14px;
  border-radius: 6px;
  border: solid 2px #1872F0;
  background-color: #1872F0;
  font-family: "CircularStd";
  font-weight: 600;
  width: 165px;
  

  :hover {
    background: #1C508F;
    border: 2px solid #1C508F;
    color: #fff;
  }

  ${props =>
    props.outline &&
    css`
      color: #1872F0;
      border: solid 2px #1872F0;
      background-color: #ffffff;
    `}
`;

export default Button;
