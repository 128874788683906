import React from "react";
import styled from "styled-components";

const Created = styled.p`
  width: 33.3%; 
  color: #BDBDBD;
  font-size: 12px;
  font-weight: 300;
  line-height: 13px;
  text-align: center;
`;

const Rights = styled.p`
  width: 33.3%;
  color: #BDBDBD;
  font-size: 12px;
  font-weight: 300;
  line-height: 13px;
  text-align: center;
`;

const Wrapper = styled.footer`
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1C508F
  @media screen and (max-width: 800px) {
    flex-direction: column;
    p {
      width: 100%;
    }
  };
`

const Footer = () => (
  <Wrapper>
    <Created>
      Designed and developed by{" "}
      <a style={{color: "#BDBDBD"}}href="https://verybigthings.com/">Very Big Things</a>
    </Created>
    <Rights>©2019 All Rights Reserved</Rights>
  </Wrapper>
);

export default Footer;
