import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  text-align: center;
  height: 200px;
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    height: 60px;
  }
`;

const ButtonYellow = styled.button`
  padding: 9px 17px;
  color: #fff;
  font-size: 14px;
  border-radius: 6px;
  border: solid 2px #E3A93D;
  background-color: #E3A93D;
  font-family: "CircularStd";
  font-weight: 600;
  width: 165px;

  :hover {
    background: #C96F00;
    border: 2px solid #C96F00;
    color: #fff;
  }
`;

const TryFree = ({openModal}) => (
  <ButtonWrapper>
    <ButtonYellow onClick={openModal}>
      Try Free Demo
    </ButtonYellow>
  </ButtonWrapper>
);

export default TryFree;
