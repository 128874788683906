import styled from "styled-components";

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    width: 900px;
  }

  @media screen and (max-width: 900px) {
    width: 750px;
  }

  @media screen and (max-width: 800px) {
    width: 90%;
    padding: 1em;
  }
`;

export default Container;
