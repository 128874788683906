import styled from "styled-components";

const H1 = styled.h1`
  color: #3a3f48;
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  padding: 0;

  @media screen and (max-width: 800px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export default H1;
