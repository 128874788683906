import React from "react";
import {
  Header,
  Hero,
  HowItWorks,
  OurMission,
  Testimonials,
  Footer
} from "./components";


function App() {
  return (
    <div>
      <Header />
      <main>
        <Hero />
        <HowItWorks />
        <OurMission />
        <Testimonials />
      </main>
      <Footer />
    </div>
  );
}

export default App;
