import styled, { css } from "styled-components";

const Col = styled.div`
  flex: 1;
  padding: 15px;

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}em;
    `}

  ${props =>
    props.mobile &&
    css`
      @media screen and (max-width: 800px) {
        flex-direction: row;
        display: flex;
        .text {
          padding-left: 25px;
        }
      }
    `}

  ${props =>
    props.centerMobile &&
    css`
      @media screen and (max-width: 800px) {
        text-align: center;
      }
    `}
`;

export default Col;
