import React, { Component } from "react";
import styled from "styled-components";
import { H3 } from "./index";
import Slider from "react-slick";

const Wrapper = styled.div`
  width: 700px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    width: 400px;
  }
  @media screen and (max-width: 600px) {
    width: 300px;
  }
`;

const Testimonial = styled.div`
  border-radius: 16px;
  text-align: center;
  padding: 40px;
  height: 250px;
  cursor: pointer;
  margin-bottom: 10px;
  width: initial !important;

  @media screen and (min-width: 800px) {
    height: 200px;
  }
  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 16px;
  background-color: #1872F0;
  border: none;
`;

const Caption = styled.span`
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
`;

class TestimonialSlider extends Component {
  state = {
    testimonials: [
      {
        text:
          "Being the parent of a child with disabilities, you see them hit walls so often. That’s why I want her to feel accepted somewhere, to get a job, to be independent.",
        author: "Michelle D.",
        role: "Parent of VI-ready user"
      },
      {
        text:
          "This helped me learn that it’s just like a conversation, after that I was actually more comfortable in the interview.",
        author: "Brian O.",
        role: "VI-ready user"
      },
      {
        text:
          "It feels really good to see all the things that I was able to earn with my own hard work. And it all starts with a job.",
        author: "Andrew A.",
        role: "VI-ready user"
      }
    ],
    currentIndex: 0,
    currentValue: 700
  };

  changeTestimonial = i => {
    this.setState({
      currentIndex: i
    });
  };

  render() {
    const { testimonials } = this.state;

    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      customPaging: function(i) {
        return <Dot />;
      },
      padding: "50px"
    };

    return (
      <Wrapper>
        <Slider {...settings}>
          {testimonials.map(({ text, author, role }, i) => (
            <Testimonial key={i}>
              <p style={{color: "#fff"}}>{text}</p>
              <H3 style={{ color: "#fff", margin: "10px 0" }}>{author}</H3>
              <Caption>{role}</Caption>
            </Testimonial>
          ))}
        </Slider>
      </Wrapper>
    );
  }
}

export default TestimonialSlider;
