import styled, { css } from "styled-components";

const P = styled.p`
  color: #3a3f48;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;

  ${props =>
    props.alignLeft &&
    css`
      text-align: left;
      @media screen and (max-width: 800px) {
        text-align: center;
      }
    `}
`;

export default P;
