import React from "react";
import styled from "styled-components";
import { TextContainer, P, H1 } from "./index";
import Img from "./Img";

const Wrapper = styled.div`
  text-align: center;
`;

const Iframe = styled.iframe`
  position: absolute;
  border: 0;
  border-radius: 15px;
  top: 0;
  left: 0;

  @media screen and (max-width: 700px) {
    height: 300px;
    border-radius: 0px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  height: 537px;
  width: 920px;
  top: 10px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;

  @media screen and (max-width: 1000px) {
    width: 660px;
    height: 370px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const VideoContainerBg = styled.div`
  position: relative;
  height: 550px;
  width: 1108px;
  top: -77px;
  left: -117px;

  @media screen and (max-width: 1000px) {
    width: 800px;
    top: -66px;
    left: -90px;
  }

  @media screen and (max-width: 700px) {
    width: 549px;
    top: -40px;
    left: 0;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    top: 0px;
  }
`;

const PatternContainer = styled.div`
   position: absolute;
   left: 0;
   top: 0;
   width: 404px;
   max-width: 100%;
  `;

const Header = () => (
  <Wrapper>
    <TextContainer>
      <H1>
        Leveling the playing field so everyone can build the confidence needed to achieve real-world success.
        {/* Helping people with ASD & other developmental disabilities build
        confidence and achieve real-world success */}
      </H1>
      <P>
        The VI-ready system is an innovative virtual interviewing platform that
        helps people with ASD (Autistic Spectrum Disorder) and other
        developmental disabilities practice the social skills necessary for
        obtaining employment.
      </P>
    </TextContainer>
    <VideoContainer>
      <VideoContainerBg>
        <PatternContainer style={{zIndex: -1}}>
          <Img src="/img/square_pattern.svg" />
        </PatternContainer>
      </VideoContainerBg>
      <Iframe
        width="100%"
        height="100%"
        title="dmf"
        src="https://www.youtube.com/embed/ORsR9j0bd9E?autoplay=0&modestbranding=1&rel=0&controls=0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></Iframe>
    </VideoContainer>
  </Wrapper>
);

export default Header;
