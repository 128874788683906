import React from "react";
import styled from "styled-components";
import { Button } from "./index";

const Wrapper = styled.header`
  background: white;
  padding: 100px 0 20px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 35px;
  top: 35px;
`;

const Logo = styled.img`
  max-width: 100%;
  @media screen and (max-width: 800px) {
    padding: 0 15px;
  }
`;


const Header = () => (
  <Wrapper>
    <ButtonWrapper>
      <Button href={process.env.REACT_APP_DMF_VITA} target="_blank">
        Login
      </Button>
    </ButtonWrapper>
    <Logo src="/img/logo.svg" alt="" />
  </Wrapper>
);

export default Header;
