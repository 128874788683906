import React from "react";
import styled from "styled-components";
import { H2, TestimonialSlider, Img } from "./index";

const Wrapper = styled.section`
  background-color: #1C508F;
  display: flex;
  min-height: 700px;
  justify-content: center;
  align-items: center;
  background-size: 100%;
`;

const Title = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: #fff;
  }
`;

const PatternContainer = styled.div`
  position: absolute;
  left: -180px;
  top: 40px;
  width: 404px;
  height: 404px;
  z-index: 0;
  max-width: 100%;
`;


const Backdrop = styled.div`
  position: absolute;
  background: url("/img/testimonials_bg.png") no-repeat center center;
  background-size: 100%;
  z-index: 1;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 600px) {
    background: url("/img/testimonials_bg_mobile.png") no-repeat center center;
    background-size: 100%;
  }
`;

const Testimonials = () => (
  <Wrapper className="relative">
    <Backdrop></Backdrop>
    <PatternContainer>
      <Img style={{ position: "absolute" }} src="/img/square_pattern_dark.svg" />
    </PatternContainer>
    <div className="relative">
      <Title>
        <img src="/img/speech_bubble.svg" alt=""></img>
        <H2>Testimonials</H2>
      </Title>
      <TestimonialSlider />
    </div>
  </Wrapper>
);

export default Testimonials;
